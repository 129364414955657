import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContent from "../components/page_content"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../components/broker-styles.css"
import LeftArrow from '../images/left_arrow.png'


const BrokerDemo = props => {
    var parentPage = props.pageContext.broker_slug;
    //console.log(props);

    return (
        <Layout  logo={props.pageContext.broker_logo}>
        <SEO title="Broker" />
        <PageContent>
        <Container>
            <Row style={{paddingTop:"20px",paddingBottom:"20px"}}>
                <Col>
                    <div style={{textAlign:"center",marginTop:"30px"}}>
                        Demo bits here
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{textAlign:"center",marginTop:"30px"}}>
                        <Link to={parentPage} style={{textDecoration:"none"}}>
                        <h4>
                        <img src={LeftArrow} alt="left arrow" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                            Back</h4>
                    </Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </PageContent>
    </Layout>
    )
}


export default BrokerDemo